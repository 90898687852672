import React, { useRef, ErrorInfo, createContext, useEffect, useReducer } from "react";
// third-party
import { initializeApp } from "firebase/app";
import { TCountryCode } from "countries-list";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInAnonymously,
  createUserWithEmailAndPassword,
  User,
} from "firebase/auth";
import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "firebase/auth";

// import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
  doc,
  query,
  where,
  setDoc,
  updateDoc,
  getDoc,
  Unsubscribe,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import dayjs from "dayjs";
import axios from "axios";
import { Crisp } from "crisp-sdk-web";

// Mixpanel
import mixpanel from "mixpanel-browser";

// action - state management
import {
  LOGIN,
  LOGOUT,
  IS_INITIALIZED,
  CHILDREN_IS_INITIALIZED,
  SET_CURRENT_SOFTWARE_VERSION,
  SET_COUNTRY_CODES,
  SET_URL_PARAMS,
  FETCH_USER_ORG_SUCCESS,
  FETCH_ORG_PRIVATE_DATA_SUCCESS,
  FETCH_ORG_TO_ORG_PROVIDER_SUBSCRIPTIONS_SUCCESS,
  FETCH_ORG_TO_ORG_CONSUMER_SUBSCRIPTIONS_SUCCESS,
  FETCH_USER_ONBOARDING_OBJECT_SUCCESS,
  SET_ERROR,
  SET_LOADING,
  DELETE_TEAM_MEMBER_FROM_STATE,
  ADDED_ORG_CONTACT_SUCCESS,
  MODIFIED_ORG_CONTACT_SUCCESS,
  REMOVED_ORG_CONTACT_SUCCESS,
  ORG_CONTACTS_EMPTY,
  DELETE_CONTACT_FROM_STATE,
  ADDED_ORG_TEAM_MEMBER_SUCCESS,
  MODIFIED_ORG_TEAM_MEMBER_SUCCESS,
  REMOVED_ORG_TEAM_MEMBER_SUCCESS,
  FETCH_SPECIES_DATA_SUCCESS,
  FETCH_ACTIVITY_DATA_SUCCESS,
  FETCH_SUBSCRIPTION_ADD_ONS_SUCCESS,
  FETCH_EXTERNAL_WMS_LAYERS_SUCCESS,
} from "store/reducers/actions";
import authReducer from "store/reducers/auth";

// project import
import Loader from "components/Loader";
import { FIREBASE_API, CLOUD_FUNCTIONS_URL, CRISP_KEY, MP_TOKEN, MP_DEBUG } from "config/config";
import { convertUrlQueryString } from "features/map/utils/convertUrlQueryString";
import {
  AuthProps,
  CloudFunctionNames,
  FirebaseContextType,
  OnboardingObject,
  OrgToOrgSubscription,
  RoleTypes,
  SubscriptionTypes,
  UserOrg,
  UserProfile,
  UserSettingsObject,
} from "types/auth";
import { useTranslation } from "utils/locales/utilityFunctions";
import anonymousConfig from "config/appConfigFiles/anonymous.json";
import useAppFunctionalityConfig from "hooks/useAppFunctionalityConfig";
import { getAppConfigFromSubscription } from "config/utils/getAppConfig";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";
import { loadGooglePlacesAutocompleteScript } from "utils/scriptLoader";
import { dataObjectWithoutUndefinedFields } from "utils/dataManipulation";
import useAppSettings from "hooks/useAppSettings";
import {
  mapOnboardingStepToMixpanelUserOnbardingStep,
  mixpanelEvents,
} from "config/servicesConfig/mixpanelEvents";

// --- Mixpanel initialize --- //
mixpanel.init(MP_TOKEN, {
  debug: MP_DEBUG,
  track_pageview: "full-url",
  persistence: "localStorage",
});

// --- firebase initialize --- //
export const firebaseApp = initializeApp(FIREBASE_API);
// const analytics = getAnalytics(firebaseApp);
export const firestore = getFirestore(firebaseApp);
// Get callable http functions from backend
const functions = getFunctions(firebaseApp, "europe-west3");
// Init functions
const initUserAndOrg = httpsCallable(functions, "init-userAndOrg");
// Team functions
const createTeamMember = httpsCallable(functions, "team-create");
const readTeamMember = httpsCallable(functions, "team-read");
const updateTeamMember = httpsCallable(functions, "team-update");
const deleteTeamMember = httpsCallable(functions, "team-delete");
// Contact functions
const createContact = httpsCallable(functions, "contacts-create");
const readContact = httpsCallable(functions, "contacts-read");
const updateContact = httpsCallable(functions, "contacts-update");
const deleteContact = httpsCallable(functions, "contacts-delete");
// Data functions
const setDataStatus = httpsCallable(functions, "data-setDataStatus");
const createMetaData = httpsCallable(functions, "data-createMetaElement");
const updateMapData = httpsCallable(functions, "data-updateMapData");
const orgToOrgSubscription = httpsCallable(functions, "data-orgToOrgSubscription");
const syncSubscription = httpsCallable(functions, "data-syncReepaySubscription");
const updateSubscription = httpsCallable(functions, "data-updateReepaySubscription");
const getCheckoutSession = httpsCallable(functions, "data-getReepayCheckoutSession");
// Audience functions
const setAudienceState = httpsCallable(functions, "audience-set");
// External services
const getCompanyData = httpsCallable(functions, "externalServices-getCompanyData");

const getter = <A,>(url: string) => {
  let data: null | A = null;
  let authed = false;
  return async () => {
    if (authed) return data;
    try {
      const token = await getAuth(firebaseApp)?.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const res = await axios.get<A>(url, { headers: { Authorization: "Bearer " + token } });
      data = res.data;
      authed = true;
      return data;
    } catch (error) {
      console.error("Error while getting a key", error);
      return null;
    }
  };
};

export const getOpenWeather = getter<{ apiKey: string }>(
  CLOUD_FUNCTIONS_URL + "keyGetters-getOpenWeather"
);
export const getTodaysforestInternalApiKey = getter<string>(
  CLOUD_FUNCTIONS_URL + "keyGetters-getTodaysforestInternalApiKey"
);
export const getGoogleMapsApiKey = getter<string>(
  CLOUD_FUNCTIONS_URL + "keyGetters-getGoogleMapsApiKey"
);

// Get auth object
const auth = getAuth(firebaseApp);

// Firestore event listeners
let firestoreEventListeners: any[] = [];
let orgContactsUnsubscribe: Unsubscribe | null = null;
let orgInvitesUnsubscribe: Unsubscribe | null = null;

// const
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  childrenInitialized: false,
  currentSoftwareVersion: "",
  error: null,
  user: null,
  userOnboardingObject: null,
  userOrg: null,
  orgToOrgProviderSubscriptions: null,
  orgToOrgConsumerSubscriptions: null,
  authUserOrgRole: "not-admin",
  urlParams: null,
  loading: false,
  orgTeam: {},
  orgContacts: {},
  cloudFunctionLoading: false,
  cloudFunctionError: null,
  removeUserId: null,
  species: {},
  activityTypes: {},
  subscriptionAddOns: null,
  speciesOverwrites: null,
  externalWMSLayers: null,
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //
const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const FirebaseProvider = ({ children }: { children: React.ReactElement }) => {
  // --- Hooks --- //
  const { translate } = useTranslation();
  const {
    trialSubscription,
    fetchUserAppSettings,
    updateUserAppSettings,
    setTrialSubscription,
    setMapBackgroundLayer,
  } = useAppSettings();
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { onChangeAppFunctionalityConfig } = useAppFunctionalityConfig();
  // --- state and refs --- //
  const subscriptionSyncedRef = useRef(false);
  const orgPrivateDataFetchedRef = useRef(false);
  const tempTrialSubscriptionRef = useRef<SubscriptionTypes | "unknown">("unknown");
  const tempUserRoleRef = useRef<"admin" | "not-admin">("not-admin");
  const firstRenderRef = useRef(true);

  // --- Auth state change effect hook --- //
  useEffect(
    () =>
      onAuthStateChanged(auth, (user: any) => {
        if (user) {
          // Fetch from firestore
          fetchCurrentSoftwareVersion();
          fetchUserData(user);
          fetchSpeciesList("extended");
          fetchActivityTypes("extended");
          fetchSubscriptionAddOns();
          fetchUserAppSettings(user.uid);
          // Setup third party services
          Crisp.configure(CRISP_KEY);
          Crisp.chat.hide(); // <-- enabled after life ring hide
          mixpanel.identify(user.uid);
          // Load global scripts
          loadGlobalScripts();
        } else {
          // Unsubscribe from event listeners
          if (!state.isLoggedIn && firestoreEventListeners.length > 0) {
            firestoreEventListeners.forEach((el) => el());
          }
          if (orgContactsUnsubscribe) orgContactsUnsubscribe();
          if (orgInvitesUnsubscribe) orgInvitesUnsubscribe();
          // Reset mixpanel user
          mixpanel.reset();
          dispatch({
            type: LOGOUT,
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  function initializeMapsApp() {}

  async function loadGlobalScripts() {
    try {
      const googleMapsApiKey = await getGoogleMapsApiKey();
      if (googleMapsApiKey) {
        loadGooglePlacesAutocompleteScript(googleMapsApiKey, initializeMapsApp);
      }
    } catch (error) {
      console.error("Error loading google places autocomplete", error);
    }
  }

  // --- Set user app settings data --- //
  useEffect(() => {
    // Check when user has not yet created an org (only in the beginning of creating the user and onboarding)
    if (
      !state.userOrg ||
      Object.keys(state.userOrg).length === 0 ||
      !state.orgPrivateData ||
      Object.keys(state.orgPrivateData).length === 0
    ) {
      // Check if user has been onboarded
      if (state.user && state.user.id && !state.user.onboarded) {
        // Initialize subscription
        updateUserAppSettings(state.user.id!, { trialSubscription: "basic" });
        setTrialSubscription("basic");
        const newConfig = getAppConfigFromSubscription({ type: "basic" }, "admin");
        onChangeAppFunctionalityConfig(newConfig, "basic");
        // Initialize user background layer
        setMapBackgroundLayer("googleTerrain");
        return;
      }
    }
    // Check when user has an org
    if (state.user && state.user.id && state.userOrg && state.orgPrivateData) {
      // Check for trial subscription
      if (state.userOrg.subscription?.type === "trial") {
        let userRole: "admin" | "not-admin" = "not-admin";
        if (state.orgPrivateData.roles && state.orgPrivateData.roles[state.user.id]) {
          userRole = state.orgPrivateData.roles[state.user.id].role;
          if (userRole === "not-admin") {
            const newConfig = getAppConfigFromSubscription({ type: "basic" }, userRole);
            onChangeAppFunctionalityConfig(newConfig, "basic");
            tempTrialSubscriptionRef.current = trialSubscription;
            return;
          }
        }

        if (trialSubscription === "unknown") {
          updateUserAppSettings(state.user.id!, { trialSubscription: "basic" });
          setTrialSubscription("basic");
          const newConfig = getAppConfigFromSubscription({ type: "basic" }, userRole);
          onChangeAppFunctionalityConfig(newConfig, "basic");
          return;
        }

        if (
          trialSubscription !== tempTrialSubscriptionRef.current ||
          userRole !== tempUserRoleRef.current
        ) {

          let trialSubName: SubscriptionTypes | "unknown" = "basic";
          // @ts-ignore
          if (trialSubscription && trialSubscription !== "unknown") {
            trialSubName = trialSubscription;
          }
          const newConfig = getAppConfigFromSubscription({ type: trialSubName }, userRole);
          // @ts-ignore
          onChangeAppFunctionalityConfig(newConfig, trialSubName);
          tempTrialSubscriptionRef.current = trialSubscription;
          tempUserRoleRef.current = userRole;
        }
      }
    }
  }, [state.user, state.userOrg, state.orgPrivateData, trialSubscription]);

  // --- Fetch org and init sync subscription private data
  useEffect(() => {
    if (state.userOrg?.id && state.userOrg?.subscription) {
      if (!orgPrivateDataFetchedRef.current) {
        fetchOrgPrivateData(state.userOrg?.id);
        fetchOrgToOrgProviderSubscriptions(state.userOrg?.id);
        fetchOrgToOrgConsumerSubscriptions(state.userOrg?.id);
        orgPrivateDataFetchedRef.current = true;
      }
      if (!subscriptionSyncedRef.current) {
        callCloudFunction("syncSubscription", { orgId: state.userOrg?.id });
        subscriptionSyncedRef.current = true;
      }
    }
  }, [state.userOrg]);

  // Fetch external WMS layers according to subscription and countries
  useEffect(() => {
    if (state.userOrg && !firstRenderRef.current) {
      const countryCode =
        state.countryCodes && state.countryCodes.length > 0
          ? state.countryCodes
          : state.userOrg.countryCode
          ? state.userOrg.countryCode
          : "";
      let subType = state.userOrg.subscription ? state.userOrg.subscription.type : "free";
      if (subType === "trial" && trialSubscription !== "unknown") {
        subType = trialSubscription;
      }
      fetchExternalWMSLayers("countryCode", countryCode, subType);
    }
    firstRenderRef.current = false;
  }, [state.userOrg, state.countryCodes, trialSubscription]);

  useEffect(() => {
    if (
      state.user &&
      state.user.id &&
      state.userOrg?.subscription &&
      state.userOrg?.subscription.type !== "trial" &&
      state.orgPrivateData
    ) {
      // Find user org role
      let userRole: "admin" | "not-admin" = "not-admin";
      if (state.orgPrivateData.roles && state.orgPrivateData.roles[state.user?.id]) {
        userRole = state.orgPrivateData.roles[state.user?.id].role;
      }
      const config = getAppConfigFromSubscription(state.userOrg.subscription, userRole);
      onChangeAppFunctionalityConfig(config, config.name);
    }
  }, [state.userOrg, state.orgPrivateData]);

  // --- Fetch org team --- //
  useEffect(() => {
    if (state.userOrg?.id && state.orgPrivateData?.roles) {
      fetchOrgTeam(state.userOrg.id);
      if (state.userOrg.contacts) {
        fetchOrgContacts(state.userOrg.contacts);
      }
    }
  }, [state.userOrg, state.orgPrivateData]);

  // --- Set deep links --- //
  useEffect(() => {
    if (window.location.search) {
      const urlParams = convertUrlQueryString(window.location.search);
      setUrlParams(urlParams);
    }
  }, []);

  // --- Reducer dispatch functions --- //
  const setUrlParams = (params: any) => {
    dispatch({
      type: SET_URL_PARAMS,
      payload: {
        urlParams: params,
      },
    });
  };

  // --- Set children initialized --- //
  const setChildrenInitialized = () => {
    dispatch({
      type: CHILDREN_IS_INITIALIZED,
    });
  };

  // --- Set countryCodes --- //
  const setCountryCodes = (countryCodes: TCountryCode[]) => {
    dispatch({
      type: SET_COUNTRY_CODES,
      payload: {
        countryCodes: countryCodes,
      },
    });
  };

  // --- Email functionality --- //
  const sendEmail = async (to: string, subject: string, text?: string) => {
    dispatch({ type: SET_LOADING, payload: { loading: true } });
    try {
      await addDoc(collection(firestore, "mail"), {
        to: to,
        message: {
          subject: subject,
          text: text ? text : "",
        },
      });
      dispatch({ type: SET_LOADING, payload: { loading: false } });
    } catch (error) {
      console.error("Send email ERROR", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };
  // --- Sign in user --- //
  const firebaseAnonymousSignIn = () => signInAnonymously(auth);

  const firebaseEmailPasswordSignIn = (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password);

  const firebaseGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const firebaseTwitterSignIn = () => {
    const provider = new TwitterAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const firebaseFacebookSignIn = () => {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(auth, provider);
  };

  // --- Register user --- //
  const firebaseRegister = async (email: string, password: string) =>
    createUserWithEmailAndPassword(auth, email, password);

  // --- Logout user --- //
  const signalLogout = () => {
    dispatch({
      type: LOGOUT,
    });
  };
  const logout = async () => {
    signalLogout();
    console.time("Logout");
    // await wait(10000);
    console.timeEnd("Logout");
    await signOut(auth);
    window.location.reload();
    return;
  };

  // --- Reset user password --- //
  const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(auth, email);
  };

  // --- Update user profile --- //
  async function updateProfile(user: UserProfile, newUser?: boolean) {
    dispatch({ type: SET_LOADING, payload: { loading: true } });
    try {
      const docRef = doc(firestore, `users/${user.id}`);
      // Create new user in users collection
      if (newUser) {
        await setDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(user),
          acceptedTerms: true,
          newUser: true,
          onboarded: false,
          creationDate: dayjs().format(),
          lastModifiedDate: dayjs().format(),
        });
        mixpanel.people.set({
          $accountRole: "Creator",
        });
      } else {
        await updateDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(user),
          lastModifiedDate: dayjs().format(),
        });
      }
      dispatch({ type: SET_LOADING, payload: { loading: false } });
    } catch (error) {
      console.error("updateProfile error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  }

  const updateUserOnboardingObject = async (userId: string, onbObj: OnboardingObject) => {
    dispatch({ type: SET_LOADING, payload: { loading: true } });
    try {
      const docRef = doc(firestore, `users/${userId}/private_data/onboarding`);
      await setDoc(
        docRef,
        {
          ...dataObjectWithoutUndefinedFields(onbObj),
        },
        { merge: true }
      );
      // Log event in mixpanel (onboarding step)
      mixpanel.people.set({
        onboardingStep: mapOnboardingStepToMixpanelUserOnbardingStep(onbObj),
      });
      if (onbObj.questionaireAnswers?.roleId) {
        mixpanel.people.set({
          $userRoles: onbObj.questionaireAnswers.roleId,
        });
      }
      dispatch({ type: SET_LOADING, payload: { loading: false } });
    } catch (error) {
      console.error("updateUserOnboarding error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  const updateUserSettings = async (userId: string, data: UserSettingsObject) => {
    dispatch({ type: SET_LOADING, payload: { loading: true } });
    try {
      const docRef = doc(firestore, `users/${userId}/private_data/settings`);
      await setDoc(
        docRef,
        {
          ...dataObjectWithoutUndefinedFields(data),
        },
        { merge: true }
      );
      dispatch({ type: SET_LOADING, payload: { loading: false } });
    } catch (error) {
      console.error("updateUserSettings error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  // --- Update organisation data --- //
  const updateOrganization = async (orgId: string | null, data: UserOrg, companyData?: any) => {
    dispatch({ type: SET_LOADING, payload: { loading: true } });
    try {
      let docId = null;
      if (!orgId) {
        // Create organization
        const docRef = doc(collection(firestore, "orgs"));
        docId = docRef.id;
        await setDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(data),
          id: docRef.id,
          countryCode: state.user?.countryCode ? state.user?.countryCode : "DK",
          email: state.user?.email,
          creationDate: dayjs().format(),
          lastModifiedDate: dayjs().format(),
        });
        // Link current user to new org as admin
        await callCloudFunction("updateTeamMember", {
          id: state.user?.id,
          orgId: docRef.id,
          disableOrgAdmin: true,
          orgs: [
            {
              id: docRef.id,
              role: "admin",
              name: data.name ? data.name : "",
              inviterId: state.user?.id,
            },
          ],
        });
        // Initialize user and org (get invitation data if any, else create first estate and map)
        await callCloudFunction("initUserAndOrg", {
          id: state.user?.id,
          email: state.user?.email,
          orgId: docId,
          countryCode: state.user?.countryCode ? state.user?.countryCode : "DK",
        });
      } else {
        const docRef = doc(firestore, `orgs/${orgId}`);
        docId = orgId;
        await updateDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(data),
          lastModifiedDate: dayjs().format(),
        });
      }
      // If external company registration data, set it on cloud
      if (companyData) {
        const docRef = doc(firestore, `orgs/${docId}/private_data/companyData`);
        await setDoc(
          docRef,
          {
            ...companyData,
          },
          { merge: true }
        );
      }
      dispatch({ type: SET_LOADING, payload: { loading: false } });
    } catch (error) {
      console.error("updateOrg error", { error });
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  const deleteOrgToOrgSubscription = async (id: string) => {
    dispatch({ type: SET_LOADING, payload: { loading: true } });
    try {
      const docRef = doc(firestore, `orgToOrgSubscriptions/${id}`);
      await deleteDoc(docRef);
      // Log event in mixpanel
      mixpanel.track(mixpanelEvents.orgToOrgSubscriptionDeleted);
      dispatch({ type: SET_LOADING, payload: { loading: false } });
    } catch (error) {
      console.error("deleteOrgToOrgSubscription error", { error });
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  // --- Cloud Functions --- //
  const callCloudFunction = async (fcn: CloudFunctionNames, data: any) => {
    switch (fcn) {
      // Init
      case "initUserAndOrg":
        return initUserAndOrg(data);
      // Team
      case "createTeamMember":
        // Log event in mixpanel
        mixpanel.track(mixpanelEvents.teamMemberCreated);
        return createTeamMember(data);
      case "readTeamMember":
        return readTeamMember(data);
      case "updateTeamMember":
        return updateTeamMember(data);
      case "deleteTeamMember":
        // Log event in mixpanel
        mixpanel.track(mixpanelEvents.teamMemberDeleted);
        return deleteTeamMember(data);
      // Contacts
      case "createContact":
        // Log event in mixpanel
        mixpanel.track(mixpanelEvents.contactCreated);
        return createContact(data);
      case "readContact":
        return readContact(data);
      case "updateContact":
        return updateContact(data);
      case "deleteContact":
        // Log event in mixpanel
        mixpanel.track(mixpanelEvents.contactDeleted);
        return deleteContact(data);
      // Audience
      case "setAudienceState":
        return setAudienceState(data);
      // Data
      case "setDataStatus":
        return setDataStatus(data);
      case "createMetaData":
        return createMetaData(data);
      case "updateMapData":
        return updateMapData(data);
      case "orgToOrgSubscription":
        return orgToOrgSubscription(data);
      case "syncSubscription":
        return syncSubscription(data);
      case "updateSubscription":
        return updateSubscription(data);
      case "getCheckoutSession":
        return getCheckoutSession(data);
      // External services
      case "getCompanyData":
        return getCompanyData(data);
      // If unknown throw error
      default:
        throw new Error("cloud-function-unknown");
    }
  };

  // --- Fetch current software version --- //
  const fetchCurrentSoftwareVersion = async () => {
    try {
      const path = "data/version";
      const docRef = doc(firestore, path);
      const unsub = onSnapshot(docRef, (doc) => {
        if (doc.exists() && doc.data()) {
          dispatch({
            type: SET_CURRENT_SOFTWARE_VERSION,
            payload: {
              currentSoftwareVersion: doc.data().version ? doc.data().version : "",
            },
          });
        }
      });
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error("Fetch software version error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  // --- Fetch configuration data --- //
  const fetchSpeciesList = async (availability?: "simple" | "extended") => {
    try {
      const path = `data/stand/species`;
      // Set up query
      let q = null;
      if (availability && availability === "simple") {
        q = query(collection(firestore, path), where("availability", "==", "simple"));
      } else {
        q = query(collection(firestore, path));
      }
      const unsub = onSnapshot(
        q,
        (qs) => {
          let data = {} as any;
          qs.forEach((doc: any) => {
            data = { ...data, [doc.id]: doc.data() };
          });
          dispatch({
            type: FETCH_SPECIES_DATA_SUCCESS,
            payload: {
              species: data,
            },
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error("Fetch species data error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  const fetchActivityTypes = async (availability?: "simple" | "extended") => {
    try {
      const path = `data/activities/types`;
      // Set up query
      let q = null;
      if (availability && availability === "simple") {
        q = query(collection(firestore, path), where("availability", "==", "simple"));
      } else {
        q = query(collection(firestore, path));
      }
      const unsub = onSnapshot(
        q,
        (qs) => {
          let data = {} as any;
          qs.forEach((doc: any) => {
            data = { ...data, [doc.id]: doc.data() };
          });
          dispatch({
            type: FETCH_ACTIVITY_DATA_SUCCESS,
            payload: {
              activityTypes: data,
            },
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error("Fetch activity types error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  const fetchSubscriptionAddOns = async () => {
    try {
      const path = `data/reepay/addOns`;
      const unsub = onSnapshot(
        collection(firestore, path),
        (qs) => {
          let data: any[] = [];
          qs.forEach((doc: any) => {
            data.push({ ...doc.data() });
          });
          dispatch({
            type: FETCH_SUBSCRIPTION_ADD_ONS_SUCCESS,
            payload: {
              subscriptionAddOns: data,
            },
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error("Fetch subscription add-ons error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  // --- Fetch user data --- //
  const fetchUserData = async (user: User) => {
    dispatch({ type: SET_LOADING, payload: { loading: true } });
    try {
      const path = `users/${user.uid}`;
      let initWithoutOrg = true;
      const unsub = onSnapshot(
        doc(firestore, path),
        async (doc) => {
          let userData: any = {
            id: user.uid,
            email: user.email!,
            firstName: translate("anonymous"),
            authType: user.isAnonymous ? "anonymous" : "normal",
          };
          if (doc.exists()) {
            userData = { ...userData, ...doc.data() };
            // Fetch user org
            if (userData.orgId) {
              fetchUserOrg(userData.orgId);
              initWithoutOrg = false;
            }
            if (!userData.onboarded) await fetchUserOnboardingObject(userData.id);
          }
          // Set application config according to user profile
          if (userData.authType === "anonymous") {
            // @ts-ignore
            onChangeAppFunctionalityConfig(anonymousConfig, "anonymous");
          }
          // Send user data to mixpanel
          mixpanel.people.set({
            $email: userData.email,
            $first_name: userData.firstName,
            $last_name: userData.lastName,
            $name: userData.firstName + " " + userData.lastName,
            $created: userData.creationDate,
          });
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: { ...userData },
            },
          });
          if (initWithoutOrg) {
            dispatch({
              type: IS_INITIALIZED,
            });
          }
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error("Fetch user data error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };
  // Fetch user onboarding object (used to preload onboarding)
  const fetchUserOnboardingObject = async (userId: string) => {
    try {
      const path = `users/${userId}/private_data/onboarding`;
      const onbDoc = await getDoc(doc(firestore, path));
      let onbObj = null;
      if (onbDoc.exists()) onbObj = onbDoc.data() as OnboardingObject;
      dispatch({
        type: FETCH_USER_ONBOARDING_OBJECT_SUCCESS,
        payload: {
          userOnboardingObject: onbObj,
        },
      });
    } catch (error) {
      console.error("Fetch user onboarding data error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  // --- Fetch user org --- //
  const fetchUserOrg = async (orgId: string) => {
    dispatch({ type: SET_LOADING, payload: { loading: true } });
    try {
      // Fetch org document
      const path = `orgs/${orgId}`;
      const unsub = onSnapshot(
        doc(firestore, path),
        async (doc) => {
          let orgData = null;
          if (doc.exists()) {
            orgData = { ...doc.data() } as UserOrg;
          }
          dispatch({
            type: FETCH_USER_ORG_SUCCESS,
            payload: {
              userOrg: orgData,
            },
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error("Fetch user org data error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };
  // Fetch org private data
  const fetchOrgPrivateData = async (orgId: string) => {
    try {
      const path = `orgs/${orgId}/private_data/private`;
      const unsub = onSnapshot(
        doc(firestore, path),
        async (doc) => {
          const privateData = { ...doc?.data() };
          let authUserOrgRole: RoleTypes = "not-admin";
          // Set the authenticated users org role
          if (
            state.user &&
            state.user.id &&
            privateData &&
            privateData.roles &&
            privateData.roles[state.user.id]
          ) {
            authUserOrgRole = privateData.roles[state.user.id].role;
            // Log event in mixpanel
            mixpanel.people.set({
              $orgId: orgId,
              $orgRole: authUserOrgRole,
            });
          }
          // Disapatch to reducer
          // dataFetchingFinishedRef.current = true;
          dispatch({
            type: FETCH_ORG_PRIVATE_DATA_SUCCESS,
            payload: {
              orgPrivateData: privateData,
              authUserOrgRole: authUserOrgRole,
            },
          });
          dispatch({
            type: IS_INITIALIZED,
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error("Fetch org private data error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  const fetchOrgToOrgProviderSubscriptions = async (orgId: string) => {
    try {
      const path = `orgToOrgSubscriptions`;
      const q = query(collection(firestore, path), where("providerOrgId", "==", orgId));
      // const querySnapshot = await getDocs(q);
      const unsub = onSnapshot(q, (qs) => {
        let data: OrgToOrgSubscription[] = [];
        qs.forEach((doc: any) => {
          data.push(doc.data() as OrgToOrgSubscription);
        });
        dispatch({
          type: FETCH_ORG_TO_ORG_PROVIDER_SUBSCRIPTIONS_SUCCESS,
          payload: {
            orgToOrgProviderSubscriptions: data,
          },
        });
      });
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error("Fetch org to org subscriptions error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  const fetchOrgToOrgConsumerSubscriptions = async (orgId: string) => {
    try {
      const path = `orgToOrgSubscriptions`;
      const q = query(collection(firestore, path), where("consumerOrgId", "==", orgId));
      const unsub = onSnapshot(q, (qs) => {
        let data: OrgToOrgSubscription[] = [];
        qs.forEach((doc: any) => {
          data.push(doc.data() as OrgToOrgSubscription);
        });
        dispatch({
          type: FETCH_ORG_TO_ORG_CONSUMER_SUBSCRIPTIONS_SUCCESS,
          payload: {
            orgToOrgConsumerSubscriptions: data,
          },
        });
      });
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error("Fetch org to org subscriptions error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  // Fetch external data layers (wms, wfs, ...)
  const fetchExternalWMSLayers = async (
    queryType: "orgId" | "estateId" | "countryCode",
    idStr: string | string[],
    subscription: SubscriptionTypes
  ) => {
    try {
      // Setup query
      const path = `data/externalDataLayers/wmsLayers/`;
      let c1 = null;
      const c2 = where("subscriptionAvailability", "array-contains", subscription);
      const c3 = where("status", "==", "enabled");

      if (queryType === "countryCode") {
        if (Array.isArray(idStr)) {
          c1 = where("countryCode", "in", idStr);
        } else {
          c1 = where("countryCode", "==", idStr);
        }
      } else if (queryType === "orgId") {
        c1 = where("orgId", "==", idStr);
      } else if (queryType === "estateId") {
        c1 = where("estateId", "==", idStr);
      }
      if (!c1) return;
      const q = query(collection(firestore, path), c1, c2, c3);
      const querySnapshot = await getDocs(q);
      let data: any = {};
      querySnapshot.forEach((doc) => {
        data = { ...data, [doc.id]: doc.data() };
      });
      dispatch({
        type: FETCH_EXTERNAL_WMS_LAYERS_SUCCESS,
        payload: {
          externalWMSLayers: data,
        },
      });
    } catch (error) {
      console.error("Fetch external WMS layers error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  // --- Fetch org team --- //
  const fetchOrgTeam = async (orgId: string) => {
    try {
      const q = query(collection(firestore, "users"), where("orgId", "==", orgId));
      const unsub = onSnapshot(
        q,
        async (qs) => {
          qs.docChanges().forEach((change: any) => {
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_ORG_TEAM_MEMBER_SUCCESS,
                  payload: { memberData: { ...change.doc.data() } },
                });
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_ORG_TEAM_MEMBER_SUCCESS,
                  payload: { memberData: { ...change.doc.data() } },
                });
                break;
              case "removed":
                dispatch({
                  type: REMOVED_ORG_TEAM_MEMBER_SUCCESS,
                  payload: { memberData: { ...change.doc.data() } },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error("Fetch user org team data error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  // --- Delete team member from reducer state --- //
  const deleteTeamMemberFromState = (id: string) => {
    dispatch({ type: DELETE_TEAM_MEMBER_FROM_STATE, payload: { removeUserId: id } });
  };

  // --- Fetch org contacts --- //
  const fetchOrgContacts = async (contactList: { id: string; userCreated: boolean }[]) => {
    dispatch({ type: SET_LOADING, payload: { loading: true } });
    try {
      if (orgContactsUnsubscribe) orgContactsUnsubscribe();
      if (orgInvitesUnsubscribe) orgInvitesUnsubscribe();
      // Fetch contacts data
      const inviteIds: string[] = [];
      const contactIds: string[] = [];
      contactList.forEach((el) => {
        if (el.userCreated) contactIds.push(el.id);
        else inviteIds.push(el.id);
      });
      // Query for contacts where user has been created
      if (contactIds.length > 0) {
        const uQuery = query(collection(firestore, "users"), where("id", "in", contactIds));
        orgContactsUnsubscribe = onSnapshot(
          uQuery,
          async (qs) => {
            qs.docChanges().forEach((change: any) => {
              switch (change.type) {
                case "added":
                  dispatch({
                    type: ADDED_ORG_CONTACT_SUCCESS,
                    payload: { contactData: { ...change.doc.data(), status: "user-created" } },
                  });
                  break;
                case "modified":
                  dispatch({
                    type: MODIFIED_ORG_CONTACT_SUCCESS,
                    payload: { contactData: { ...change.doc.data(), status: "user-created" } },
                  });
                  break;
                case "removed":
                  dispatch({
                    type: REMOVED_ORG_CONTACT_SUCCESS,
                    payload: { contactData: { ...change.doc.data(), status: "user-created" } },
                  });
                  break;
                default:
              }
            });
          },
          (error) => {
            console.info("ERROR TEST", error);
            console.trace();
          }
        );
      }
      if (inviteIds.length > 0) {
        const uQuery = query(collection(firestore, "invitations"), where("id", "in", inviteIds));
        orgInvitesUnsubscribe = onSnapshot(
          uQuery,
          async (qs) => {
            qs.docChanges().forEach((change: any) => {
              switch (change.type) {
                case "added":
                  dispatch({
                    type: ADDED_ORG_CONTACT_SUCCESS,
                    payload: { contactData: { ...change.doc.data(), status: "invite-pending" } },
                  });
                  break;
                case "modified":
                  dispatch({
                    type: MODIFIED_ORG_CONTACT_SUCCESS,
                    payload: { contactData: { ...change.doc.data(), status: "invite-pending" } },
                  });
                  break;
                case "removed":
                  dispatch({
                    type: REMOVED_ORG_CONTACT_SUCCESS,
                    payload: { contactData: { ...change.doc.data(), status: "invite-pending" } },
                  });
                  break;
                default:
              }
            });
          },
          (error) => {
            console.info("ERROR TEST", error);
            console.trace();
          }
        );
      }
      if (contactIds.length === 0 && inviteIds.length === 0) {
        dispatch({
          type: ORG_CONTACTS_EMPTY,
        });
      }
    } catch (error) {
      console.error("Fetch user org data error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  const deleteContactFromState = (id: string) => {
    dispatch({ type: DELETE_CONTACT_FROM_STATE, payload: { removeUserId: id } });
  };

  // --- Error logging --- //
  const logError = async (error: Error, errorInfo: ErrorInfo) => {
    try {
      // Log error to firebase
      const docRef = doc(collection(firestore, "appErrorLog"));
      await setDoc(docRef, {
        id: docRef.id,
        errorName: error.name,
        errorMessage: error.message,
        errorStack: error.stack,
        errorInfo: errorInfo.componentStack,
        user: state.user,
        timeStamp: dayjs().format(),
      });
    } catch (error) {
      console.error("Error logging error", error);
      dispatch({
        type: SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  };

  // --- Check for data to be loaded --- //
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        setChildrenInitialized,
        setCountryCodes,
        sendEmail,
        setUrlParams,
        logError,
        firebaseRegister,
        firebaseAnonymousSignIn,
        firebaseEmailPasswordSignIn,
        login: () => {},
        firebaseGoogleSignIn,
        firebaseTwitterSignIn,
        firebaseFacebookSignIn,
        logout,
        resetPassword,
        updateProfile,
        updateOrganization,
        deleteOrgToOrgSubscription,
        updateUserOnboardingObject,
        updateUserSettings,
        callCloudFunction,
        deleteTeamMemberFromState,
        deleteContactFromState,
      }}
    >
      <ErrorBoundary logErrorFunction={logError} translate={translate}>
        {children}
      </ErrorBoundary>
    </FirebaseContext.Provider>
  );
};

export default FirebaseContext;
