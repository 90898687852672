// action - state management
import {
   ESTATES_AND_MAPS_RESET_STATE,
   SET_SELECTED_ESTATE_ID,
   SET_SELECTED_MAP_ID,
   SET_SELECTED_PROJECT_ID,
   SET_HAS_ZOOMED,
   ESTATES_AND_MAPS_API_CALL_START,
   ESTATES_AND_MAPS_API_CALL_FAIL,
   ADDED_ESTATE_SUCCESS,
   MODIFIED_ESTATE_SUCCESS,
   REMOVED_ESTATE_SUCCESS,
   ADDED_ORG_ESTATE_SUCCESS,
   MODIFIED_ORG_ESTATE_SUCCESS,
   REMOVED_ORG_ESTATE_SUCCESS,
   ADDED_MAP_SUCCESS,
   MODIFIED_MAP_SUCCESS,
   REMOVED_MAP_SUCCESS,
   ADDED_ORG_MAP_SUCCESS,
   MODIFIED_ORG_MAP_SUCCESS,
   REMOVED_ORG_MAP_SUCCESS,
   ADDED_TEAM_MEMBER_MAP_SUCCESS,
   MODIFIED_TEAM_MEMBER_MAP_SUCCESS,
   REMOVED_TEAM_MEMBER_MAP_SUCCESS,
   ADDED_PROJECT_SUCCESS,
   MODIFIED_PROJECT_SUCCESS,
   REMOVED_PROJECT_SUCCESS,
   ADDED_ORG_PROJECT_SUCCESS,
   MODIFIED_ORG_PROJECT_SUCCESS,
   REMOVED_ORG_PROJECT_SUCCESS,
   ADDED_TEAM_MEMBER_PROJECT_SUCCESS,
   MODIFIED_TEAM_MEMBER_PROJECT_SUCCESS,
   REMOVED_TEAM_MEMBER_PROJECT_SUCCESS,
   ADDED_DATA_ROLES_SUCCESS,
   SAVE_ESTATE_SUCCESS,
   SAVE_MAP_SUCCESS,
   SAVE_PROJECT_SUCCESS,
   DELETE_MAP_SUCCESS,
   DELETE_ESTATE_SUCCESS,
   DELETE_PROJECT_SUCCESS,
   SET_APP_UPGRADE_NOTIFICATIONS,
   SET_APP_UPGRADE_LIMITS,
   SET_APP_UPGRADE_LIMITS_REACHED,
   SET_ESTATE_AND_MAP_KEYS_NOT_ALLOWED,
} from "./actions";

// Utility
import { updateObject } from "utils/updateObject";
// Types
import {
   EstatesAndMapDataProps,
   EstatesAndMapDataActionProps
} from "types/estatesAndMap";

// Initial state
const initialState: EstatesAndMapDataProps = {
   loading: false,
   error: null,
   selectedEstateId: null,
   selectedMapId: null,
   selectedProjectId: null,
   hasZoomed: null,
   estates: {},
   maps: {},
   projects: {},
   orgEstates: {},
   orgMaps: {},
   orgProjects: {},
   teamMaps: {},
   teamProjects: {},
   appUpgradeNotifications: null,
   appUpgradeLimits: null,
   estateAndMapKeysNotAllowed: null,
}

// --- In app functionality --- //
const resetState = () => {
   return initialState;
}

const setSelectedEstateId = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   return updateObject(state, {
      selectedEstateId: action.id
   });
}

const setSelectedMapId = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   return updateObject(state, {
      selectedMapId: action.id
   });
}

const setSelectedProjectId = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   return updateObject(state, {
      selectedProjectId: action.id
   });
}

const setOrgElementEstateChangedId = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   return updateObject(state, {
      orgElementEstateChangedId: action.id
   });

}

const setHasZoomed = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   return updateObject(state, {
      hasZoomed: action.payload.hasZoomed
   })
}

const setAppUpgradeNotifications = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const { appUpgradeNotifications } = action.payload!;
   return updateObject (state, {
      appUpgradeNotifications: appUpgradeNotifications
   });
} 

const setAppUpgradeLimits = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const { appUpgradeLimits } = action.payload!;
   return updateObject (state, {
      appUpgradeLimits: appUpgradeLimits
   });
}

const setAppUpgradeLimitsReached = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const { appUpgradeLimitsReached } = action.payload!;
   return updateObject (state, {
      appUpgradeLimitsReached: appUpgradeLimitsReached
   });
}

const setEstateAndMapKeysNotAllowed = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const { estateAndMapKeysNotAllowed } = action.payload!;
   return updateObject (state, {
      estateAndMapKeysNotAllowed: estateAndMapKeysNotAllowed
   });

}

// --- Fetch Data API calls --- //
const estatesAndMapsAPICallStart = (state: EstatesAndMapDataProps) => {
   return updateObject(state, { loading: true })
}

const estatesAndMapsAPICallFail = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   return updateObject(state, { loading: false, error: action.error })
}
// Estates
const addedEstateSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = { ...action.payload.dataConv };
   const estateId = data.id;
   let updatedEstates = { ...state.estates };
   // Add data
   updatedEstates = { ...updatedEstates, [estateId]: data };
   return updateObject(state, {
      loading: false,
      estates: updatedEstates,
      changeEstateId: estateId
   });
}
const modifiedEstateSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const estateId = data.id;
   let updatedEstates = { ...state.estates };
   // Add data if estate is still in the reducer
   if (updatedEstates[estateId]) {
      updatedEstates[estateId] = { ...updatedEstates[estateId], ...data };
   }
   return updateObject(state, {
      loading: false,
      estates: updatedEstates,
      changeEstateId: estateId
   });
}
const removedEstateSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const estateId = data.id;
   let updatedEstates = { ...state.estates };
   // Add data
   delete updatedEstates[estateId];
   return updateObject(state, {
      loading: false,
      estates: updatedEstates,
      changeEstateId: estateId
   });
}
// Org estates
const addedOrgEstateSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = { ...action.payload.dataConv };
   const estateId = data.id;
   let updatedEstates = { ...state.orgEstates };
   // Add data
   updatedEstates = { ...updatedEstates, [estateId]: data };
   return updateObject(state, {
      loading: false,
      orgEstates: updatedEstates,
   });
}
const modifiedOrgEstateSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const estateId = data.id;
   let updatedEstates = { ...state.orgEstates };
   // Add data
   if (updatedEstates[estateId]) {
      updatedEstates[estateId] = { ...updatedEstates[estateId], ...data };
   }
   return updateObject(state, {
      loading: false,
      orgEstates: updatedEstates,
   });
}
const removedOrgEstateSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const estateId = data.id;
   let updatedEstates = { ...state.orgEstates };
   // Add data
   delete updatedEstates[estateId];
   return updateObject(state, {
      loading: false,
      orgEstates: updatedEstates,
   });
}
// Maps
const addedMapSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const mapId = data.id;
   let updatedMaps = { ...state.maps };
   // Add data
   updatedMaps = { ...updatedMaps, [mapId]: data };
   return updateObject(state, {
      loading: false,
      maps: updatedMaps,
      changeMapId: mapId
   });
}
const modifiedMapSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const mapId = data.id;
   let updatedMaps = { ...state.maps };
   // Update data
   if (updatedMaps[mapId]) {
      updatedMaps[mapId] = { ...updatedMaps[mapId], ...data };
   }
   return updateObject(state, {
      loading: false,
      maps: updatedMaps,
      changeMapId: mapId
   });
}
const removedMapSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const mapId = data.id;
   let updatedMaps = { ...state.maps };
   // Remove data
   delete updatedMaps[mapId];
   return updateObject(state, {
      loading: false,
      maps: updatedMaps,
      changeMapId: mapId
   });
}
// Org maps
const addedOrgMapSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const mapId = data.id;
   let updatedMaps = { ...state.orgMaps };
   // Add data
   updatedMaps = { ...updatedMaps, [mapId]: data };
   return updateObject(state, {
      loading: false,
      orgMaps: updatedMaps,
   });
}
const modifiedOrgMapSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const mapId = data.id;
   let updatedMaps = { ...state.orgMaps };
   // Update data
   if (updatedMaps[mapId]) {
      updatedMaps[mapId] = { ...updatedMaps[mapId], ...data };
   }
   return updateObject(state, {
      loading: false,
      orgMaps: updatedMaps,
   });
}
const removedOrgMapSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const mapId = data.id;
   let updatedMaps = { ...state.orgMaps };
   // Remove data
   delete updatedMaps[mapId];
   return updateObject(state, {
      loading: false,
      orgMaps: updatedMaps,
   });
}
// Team maps
const addedTeamMemberMapSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const mapId = data.id;
   const userId = action.payload.userId;
   let updatedMaps = { ...state.teamMaps };
   let updatedMemberMaps = { ...updatedMaps[userId]};
   // Add data
   updatedMaps = { ...updatedMaps, [userId]: { ...updatedMemberMaps, [mapId]: data } };
   return updateObject(state, {
      loading: false,
      teamMaps: updatedMaps,
   });
}
const modifiedTeamMemberMapSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const mapId = data.id;
   const userId = action.payload.userId;
   let updatedMaps = { ...state.teamMaps };
   // Update data
   if (updatedMaps[userId][mapId]) {
      updatedMaps[userId][mapId] = { ...updatedMaps[userId][mapId], ...data };
   }
   return updateObject(state, {
      loading: false,
      teamMaps: updatedMaps,
   });
}
const removedTeamMemberMapSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const mapId = data.id;
   const userId = action.payload.userId;
   let updatedMaps = { ...state.teamMaps };
   // Remove data
   delete updatedMaps[userId][mapId];
   return updateObject(state, {
      loading: false,
      teamMaps: updatedMaps,
   });
}
// Projects
const addedProjectSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const projectId = data.id;
   let updated = { ...state.projects };
   // Add data
   updated = { ...updated, [projectId]: data };
   return updateObject(state, {
      loading: false,
      projects: updated,
      changeProjectId: projectId
   });
}
const modifiedProjectSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const projectId = data.id;
   let updated = { ...state.projects };
   // Update data
   if (updated[projectId]) {
      updated[projectId] = { ...updated[projectId], ...data };
   }
   return updateObject(state, {
      loading: false,
      projects: updated,
      changeProjectId: projectId
   });
}
const removedProjectSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const projectId = data.id;
   let updated = { ...state.projects };
   // Remove data
   delete updated[projectId];
   return updateObject(state, {
      loading: false,
      projects: updated,
      changeProjectId: projectId
   });
}
// Org projects
const addedOrgProjectSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const projectId = data.id;
   let updated = { ...state.orgProjects };
   // Add data
   updated = { ...updated, [projectId]: data };
   return updateObject(state, {
      loading: false,
      orgProjects: updated,
   });
}
const modifiedOrgProjectSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const projectId = data.id;
   let updated = { ...state.orgProjects };
   // Update data
   if (updated[projectId]) {
      updated[projectId] = { ...updated[projectId], ...data };
   }
   return updateObject(state, {
      loading: false,
      orgProjects: updated,
   });
}
const removedOrgProjectSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const projectId = data.id;
   let updated = { ...state.orgProjects };
   // Remove data
   delete updated[projectId];
   return updateObject(state, {
      loading: false,
      orgProjects: updated,
   });
}
// Team projects
const addedTeamMemberProjectSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const userId = action.payload.userId;
   const projectId = data.id;
   let updated = { ...state.teamProjects };
   let updatedMemberProjects = { ...updated[userId]};
   // Add data
   updated = { ...updated, [userId]: { ...updatedMemberProjects, [projectId]: data } };
   return updateObject(state, {
      loading: false,
      teamProjects: updated,
   });
}
const modifiedTeamMemberProjectSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const userId = action.payload.userId;
   const projectId = data.id;
   let updated = { ...state.teamProjects };
   // Update data
   if (updated[userId][projectId]) {
      updated[userId][projectId] = { ...updated[userId][projectId], ...data };
   }
   return updateObject(state, {
      loading: false,
      teamProjects: updated,
   });
}
const removedTeamMemberProjectSuccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const data = action.payload.dataConv;
   const userId = action.payload.userId;
   const projectId = data.id;
   let updated = { ...state.teamProjects };
   // Remove data
   delete updated[userId][projectId];
   return updateObject(state, {
      loading: false,
      teamProjects: updated,
   });
}

// --- Data roles --- //
const addedDataRolesSucccess = (state: EstatesAndMapDataProps, action: EstatesAndMapDataActionProps) => {
   const { roles, type, userId } = action.payload
   const id = action.id;
   if (type === "estates") {
      let updated = { ...state.estates };
      if (id && updated[id]) {
         updated[id].roles = roles;
      }
      return updateObject(state, {
         loading: false,
         estates: updated
      })
   } else if (type === "orgEstates") {
      let updated = { ...state.orgEstates };
      if (id && updated[id]) {
         updated[id].roles = roles;
      }
      return updateObject(state, {
         loading: false,
         orgEstates: updated
      })
   } else if (type === "maps") {
      let updated = { ...state.maps };
      if (id && updated[id]) {
         updated[id].roles = roles;
      }
      return updateObject(state, {
         loading: false,
         maps: updated
      })
   } else if (type === "orgMaps") {
      let updated = { ...state.orgMaps };
      if (id && updated[id]) {
         updated[id].roles = roles;
      }
      return updateObject(state, {
         loading: false,
         orgMaps: updated
      })
   } else if (type === "teamMaps" && userId) {
      let updated = { ...state.teamMaps };
      if (id && userId && updated[userId][id]) {
         updated[userId][id].roles = roles;
      }
      return updateObject(state, {
         loading: false,
         teamMaps: updated
      })
   } else if (type === "projects") {
      let updated = { ...state.projects };
      if (id && updated[id]) {
         updated[id].roles = roles;
      }
      return updateObject(state, {
         loading: false,
         projects: updated
      })
   } else if ("orgProjects") {
      let updated = { ...state.orgProjects };
      if (id && updated[id]) {
         updated[id].roles = roles;
      }
      return updateObject(state, {
         loading: false,
         orgProjects: updated
      })
   } else if ("teamProjects") {
      let updated = { ...state.teamProjects };
      if (id && userId && updated[userId][id]) {
         updated[userId][id].roles = roles;
      }
      return updateObject(state, {
         loading: false,
         teamProjects: updated
      })
   } else {
      return state;
   }
}

// --- Save data API calls --- //
const saveEstateSuccess = (state: EstatesAndMapDataProps) => {
   return updateObject(state, { loading: false });
}

const saveMapSuccess = (state: EstatesAndMapDataProps) => {
   return updateObject(state, { loading: false });
}

const saveProjectSuccess = (state: EstatesAndMapDataProps) => {
   return updateObject(state, { loading: false });
}

const deleteEstateSuccess = (state: EstatesAndMapDataProps) => {
   return updateObject(state, { loading: false });
}

const deleteMapSuccess = (state: EstatesAndMapDataProps) => {
   return updateObject(state, { loading: false });
}

const deletedProjectSuccess = (state: EstatesAndMapDataProps) => {
   return updateObject(state, { loading: false });
}

const estatesAndMaps = (state = initialState, action: EstatesAndMapDataActionProps) => {
   switch (action.type) {
      case ESTATES_AND_MAPS_RESET_STATE: return resetState();
      case SET_SELECTED_ESTATE_ID: return setSelectedEstateId(state, action);
      case SET_SELECTED_MAP_ID: return setSelectedMapId(state, action);
      case SET_SELECTED_PROJECT_ID: return setSelectedProjectId(state, action);
      case SET_HAS_ZOOMED: return setHasZoomed(state, action);
      case SET_APP_UPGRADE_NOTIFICATIONS: return setAppUpgradeNotifications(state, action);
      case SET_APP_UPGRADE_LIMITS: return setAppUpgradeLimits(state, action);
      case SET_APP_UPGRADE_LIMITS_REACHED: return setAppUpgradeLimitsReached(state, action);
      case SET_ESTATE_AND_MAP_KEYS_NOT_ALLOWED: return setEstateAndMapKeysNotAllowed(state, action);

      case ESTATES_AND_MAPS_API_CALL_START: return estatesAndMapsAPICallStart(state);
      case ESTATES_AND_MAPS_API_CALL_FAIL: return estatesAndMapsAPICallFail(state, action);

      case ADDED_ESTATE_SUCCESS: return addedEstateSuccess(state, action);
      case MODIFIED_ESTATE_SUCCESS: return modifiedEstateSuccess(state, action);
      case REMOVED_ESTATE_SUCCESS: return removedEstateSuccess(state, action);

      case ADDED_ORG_ESTATE_SUCCESS: return addedOrgEstateSuccess(state, action);
      case MODIFIED_ORG_ESTATE_SUCCESS: return modifiedOrgEstateSuccess(state, action);
      case REMOVED_ORG_ESTATE_SUCCESS: return removedOrgEstateSuccess(state, action);

      case ADDED_MAP_SUCCESS: return addedMapSuccess(state, action);
      case MODIFIED_MAP_SUCCESS: return modifiedMapSuccess(state, action);
      case REMOVED_MAP_SUCCESS: return removedMapSuccess(state, action);

      case ADDED_ORG_MAP_SUCCESS: return addedOrgMapSuccess(state, action);
      case MODIFIED_ORG_MAP_SUCCESS: return modifiedOrgMapSuccess(state, action);
      case REMOVED_ORG_MAP_SUCCESS: return removedOrgMapSuccess(state, action);

      case ADDED_TEAM_MEMBER_MAP_SUCCESS: return addedTeamMemberMapSuccess(state, action);
      case MODIFIED_TEAM_MEMBER_MAP_SUCCESS: return modifiedTeamMemberMapSuccess(state, action);
      case REMOVED_TEAM_MEMBER_MAP_SUCCESS: return removedTeamMemberMapSuccess(state, action);

      case ADDED_PROJECT_SUCCESS: return addedProjectSuccess(state, action);
      case MODIFIED_PROJECT_SUCCESS: return modifiedProjectSuccess(state, action);
      case REMOVED_PROJECT_SUCCESS: return removedProjectSuccess(state, action);

      case ADDED_ORG_PROJECT_SUCCESS: return addedOrgProjectSuccess(state, action);
      case MODIFIED_ORG_PROJECT_SUCCESS: return modifiedOrgProjectSuccess(state, action);
      case REMOVED_ORG_PROJECT_SUCCESS: return removedOrgProjectSuccess(state, action);

      case ADDED_TEAM_MEMBER_PROJECT_SUCCESS: return addedTeamMemberProjectSuccess(state, action);
      case MODIFIED_TEAM_MEMBER_PROJECT_SUCCESS: return modifiedTeamMemberProjectSuccess(state, action);
      case REMOVED_TEAM_MEMBER_PROJECT_SUCCESS: return removedTeamMemberProjectSuccess(state, action);

      case ADDED_DATA_ROLES_SUCCESS: return addedDataRolesSucccess(state, action);

      case SAVE_ESTATE_SUCCESS: return saveEstateSuccess(state);
      case SAVE_MAP_SUCCESS: return saveMapSuccess(state);
      case SAVE_PROJECT_SUCCESS: return saveProjectSuccess(state);
      case DELETE_ESTATE_SUCCESS: return deleteEstateSuccess(state);
      case DELETE_MAP_SUCCESS: return deleteMapSuccess(state);
      case DELETE_PROJECT_SUCCESS: return deletedProjectSuccess(state);

      default: return state;
   }
}

export default estatesAndMaps;
