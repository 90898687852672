// material ui
import { Box, Typography, Chip } from "@mui/material";

type TipInformationProps = {
    title: string,
    content: string,
    chip?: string,
}

const TipInformation = ({ title, content, chip }: TipInformationProps) => {
    return (
        <Box sx={{ borderRadius: 1, bgcolor: 'secondary.lighter', p: 2 }}>
            <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography variant="subtitle1" sx={{
                    mr: 1,
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    maxWidth: '100%',
                    overflow: 'hidden'
                }}>
                    {title}
                </Typography>

                {chip && <Chip color="primary" label={chip} size="small" />}
            </Box>

            <Typography sx={{
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
                maxWidth: '100%',
                overflow: 'hidden'
            }}>
                {content}
            </Typography>
        </Box>
    )
}

export default TipInformation;
