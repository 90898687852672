// project import
import TipInformation from "components/display/tipInformation";
import Navigation from "./Navigation";
import SimpleBar from "components/third-party/SimpleBar";
import MainCard from "components/MainCard";
import { useTranslation } from "utils/locales/utilityFunctions";
import useAuth from "hooks/useAuth";
import { useSelector } from "react-redux";
import { RootStateProps } from "types/root";

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  // --- Hooks --- //
  const { translate } = useTranslation();
  const { userOrg } = useAuth();
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  // Check for subscription type and drawer open
  let showTrialUpgrade = false;
  if (userOrg && userOrg.subscription && userOrg.subscription.type === "trial" && drawerOpen) {
    showTrialUpgrade = true;
  }

  // --- Render --- //
  return (
    <SimpleBar
      sx={{
        "& .simplebar-content": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Navigation />
      {showTrialUpgrade && (
        <MainCard sx={{ m: 2 }} content={false}>
          <TipInformation
            title={translate("drawer-trial-functionality-header")}
            content={translate("drawer-trial-functionality-text")}
          />
        </MainCard>
      )}
    </SimpleBar>
  );
};

export default DrawerContent;
