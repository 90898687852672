import React, { createContext, useEffect, useReducer } from "react";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  onSnapshot,
  deleteDoc,
  DocumentReference,
  getDocs,
} from "firebase/firestore";
import { firestore as db } from "contexts/FirebaseContext";
import {
  MapDataProps,
  MapDataContextType,
  SaveFeatureProps,
  SaveStandDataProps,
  DeleteFeatureProps,
  SaveNoteProps,
  DeleteNoteProps,
  SaveActivityProps,
  CategoriesFilterLogicType,
} from "types/mapData";
import {
  fromFirestoreFeatureToGeoJson,
  fromGeoJsonFeatureToFirestore,
} from "@foresting-tomorrow/database_lib";
import dayjs from "dayjs";
import mapDataReducer from "store/reducers/MapData";

// action - state management
import {
  MAP_DATA_API_CALL_START,
  MAP_DATA_API_CALL_FAIL,
  FETCH_STAND_DATA_SUCCESS,
  SAVE_ACTIVITY_SUCCESS,
  SAVE_FEATURE_SUCCESS,
  DELETE_FEATURE_SUCCESS,
  SAVE_STAND_DATA_SUCCESS,
  SAVE_THINNING_DATA_SUCCESS,
  SET_SELECTED_FEATURE_ID,
  SET_SELECTED_FEATURE,
  SET_SELECTED_ACTIVITY_ID,
  SET_REMOVE_FEATURE_ID,
  SET_FEATURE_EDIT_MODE,
  SET_DRAW_MODE,
  ADDED_ACTIVITY_SUCCESS,
  MODIFIED_ACTIVITY_SUCCESS,
  REMOVED_ACTIVITY_SUCCESS,
  ADDED_FEATURE_SUCCESS,
  MODIFIED_FEATURE_SUCCESS,
  REMOVED_FEATURE_SUCCESS,
  ADDED_ACTIVITY_FEATURE_SUCCESS,
  MODIFIED_ACTIVITY_FEATURE_SUCCESS,
  REMOVED_ACTIVITY_FEATURE_SUCCESS,
  ADDED_NOTE_SUCCESS,
  MODIFIED_NOTE_SUCCESS,
  REMOVED_NOTE_SUCCESS,
  ADDED_THINNING_DATA_SUCCESS,
  MODIFIED_THINNING_DATA_SUCCESS,
  REMOVED_THINNING_DATA_SUCCESS,
  SET_ESTATE_MAP_FILTER_FEATURE_IDS,
  SET_ADD_STAND_DATA,
  SET_USE_PROGRAM_DEFINED_SETTING,
  SET_AREA_BEARING_SETTING,
  MAP_DATA_RESET_STATE,
  SET_CATEGORIES,
  SET_ACTIVE_EXTERNAL_LAYERS_LEGEND,
  SET_TABLE_STATE,
  SET_BOARD_STATE,
  SET_NOTES_LAYER_STATE,
  SET_MAP_CATEGORIES_FILTER_LOGIC_TYPE,
} from "store/reducers/actions";

import { DEFAULT_ACTIVITY_STATUS_TYPE } from "config/config-general";

import useEstatesAndMaps from "hooks/useEstatesAndMaps";
import useAuth from "hooks/useAuth";
import { MapProjectRoles } from "types/auth";
import { dataObjectWithoutUndefinedFields } from "utils/dataManipulation";
import { FirestoreActivityProps, FirestoreLayerLegend, SaveThinningProps } from "types/firestoreFeatures";
import mixpanel from "mixpanel-browser";
import { mixpanelEvents } from "config/servicesConfig/mixpanelEvents";

// Firestore event listeners
let firestoreEventListeners: any[] = [];

const initialState: MapDataProps = {
  loading: false,
  error: null,
  selectedFeatureId: null,
  selectedFeature: null,
  selectedActivityId: null,
  addStandData: true,
  useProgramDefinedSetting: true,
  areaBearingSetting: true,
  editMode: false,
  drawMode: false,
  lastSavedFeature: { id: null, geometry: null },
  changeFeature: null,
  removeFeatureId: null,
  changeNote: null,
  removeNoteId: null,
  features: {},
  standData: {},
  thinningData: {},
  activities: {},
  activityFeatures: {},
  notes: {},
  notesLayerActive: true,
  tableState: {
    inventory: {
      selectedMapIds: [],
      showColumnFilters: false,
      showFilterResultInMap: false,
      tableType: "full",
      columnFilters: [],
      columnVisibility: {},
      sorting: [],
      globalFilter: "",
      charts: {
        tabValue: 0,
        estimationParameters: {
          thinningType: undefined,
          thinningStartAge: 25,
          thinningInterval: 5,
          thinningPercentage: 30,
          sliderValue: 10,
        },
      },
    },
  },
  boardState: {
    projectBoard: {
      selectedProjectIds: [],
      showFilterResultInMap: false,
      globalFilter: "",
    },
  },
  estateMapFilterFeatureIds: {},
  activeExternalLayersLegend: [],
  categories: null,
  categoriesFilterLogicType: "AND",
};

const MapDataContext = createContext<MapDataContextType | null>(null);

export function MapDataProvider({ children }: { children: React.ReactElement }) {
  // --- Hooks --- //
  const { orgToOrgConsumerSubscriptions } = useAuth();
  const [state, dispatch] = useReducer(mapDataReducer, initialState);
  const {
    maps,
    selectedMapId,
    selectedProjectId,
    projects,
    setSelectedMapId,
    setSelectedProjectId,
  } = useEstatesAndMaps();
  const { isLoggedIn, user } = useAuth();

  // --- Reset state and unsubscribe from firestore event listeners on logout --- //
  useEffect(() => {
    if (!isLoggedIn) {
      resetState();
      if (firestoreEventListeners.length > 0) {
        firestoreEventListeners.forEach((el) => el());
      }
    }
  }, [isLoggedIn]);

  // --- Fetch data --- //
  // Map features
  useEffect(() => {
    if (selectedMapId && !state.features[selectedMapId]) {
      let categories = null;
      if (
        maps[selectedMapId] &&
        maps[selectedMapId].roles &&
        user &&
        user.id &&
        maps[selectedMapId].roles![user?.id]
      ) {
        categories =
          maps[selectedMapId].roles![user?.id].categories &&
          maps[selectedMapId].roles![user?.id].categories!.length > 0
            ? maps[selectedMapId].roles![user?.id].categories
            : null;
      }
      fetchFeatures(
        selectedMapId,
        "maps",
        user?.authType ? user?.authType : "anonymous",
        categories
      );
    }
    if (selectedMapId && !state.standData[selectedMapId]) {
      fetchStandData(selectedMapId);
      fetchThinningData(selectedMapId, "mapId");
    }
    if (selectedMapId && !state.notes[selectedMapId]) {
      if (user && user.authType !== "anonymous") {
        fetchNotes(selectedMapId);
      }
    }
  }, [selectedMapId]);
  // Project activities
  useEffect(() => {
    if (!user || !user.id) return;
    if (selectedProjectId === null && state.selectedActivityId) {
      setSelectedActivityId(null);
    }
    if (selectedProjectId && !state.activities[selectedProjectId]) {
      fetchActivities(selectedProjectId);
    }
  }, [selectedProjectId]);
  // Activity features
  useEffect(() => {
    if (state.selectedActivityId && state.selectedActivityId !== "new") {
      if (!state.activityFeatures[state.selectedActivityId]) {
        fetchActivityFeatures(state.selectedActivityId);
      }
      // Check if forest features and stands need to be fetched
      if (selectedProjectId && state.activities[selectedProjectId] && state.activities[selectedProjectId][state.selectedActivityId]) {
        const act: FirestoreActivityProps = state.activities[selectedProjectId][state.selectedActivityId];
        if (act.mapFeatureRefs && act.mapFeatureRefs.length > 0) {
          act.mapFeatureRefs.forEach((el: string) => {
            const mapId = el.split("/")[0];
            if (mapId && !state.standData[mapId]) {
              fetchStandData(mapId);
              fetchThinningData(mapId, "mapId");
            }
          });
        }
      }
    }
  }, [state.selectedActivityId]);

  // --- App specific functionality --- //
  const resetState = () => {
    dispatch({
      type: MAP_DATA_RESET_STATE,
    });
  };
  
  const setSelectedFeatureId = (id: string | null) => {
    dispatch({
      type: SET_SELECTED_FEATURE_ID,
      selectedFeatureId: id,
    });
  };

  const setSelectedFeature = (feature: null | any) => {
    let selFeatId = null;
    let selFeatGeometry = null;
    let feat = null;
    if (feature) {
      feat = feature.feature ? feature.feature : feature.toGeoJSON();
    }
    if (feat?.properties?.id) selFeatId = feature?.feature?.properties?.id;
    if (feat?.geometry) selFeatGeometry = feature?.feature?.geometry;
    dispatch({
      type: SET_SELECTED_FEATURE,
      selectedFeature: feature,
      lastSavedFeature: { id: selFeatId, geometry: selFeatGeometry },
    });
  };
  const setSelectedActivityId = (id: string | null) => {
    dispatch({
      type: SET_SELECTED_ACTIVITY_ID,
      selectedActivityId: id,
    });
  };

  const setRemoveFeatureId = (id: string | null) => {
    dispatch({
      type: SET_REMOVE_FEATURE_ID,
      removeFeatureId: id,
    });
  };

  const setAddStandData = (state: boolean) => {
    dispatch({
      type: SET_ADD_STAND_DATA,
      addStandData: state,
    });
  };

  const setUseProgramDefinedSetting = (state: boolean) => {
    dispatch({
      type: SET_USE_PROGRAM_DEFINED_SETTING,
      useProgramDefinedSetting: state,
    });
  };

  const setAreaBearingSetting = (state: boolean) => {
    dispatch({
      type: SET_AREA_BEARING_SETTING,
      areaBearingSetting: state,
    });
  };

  const setFeatureEditMode = (editMode: boolean) => {
    dispatch({
      type: SET_FEATURE_EDIT_MODE,
      editMode: editMode,
    });
  };

  const setDrawMode = (state: boolean) => {
    dispatch({
      type: SET_DRAW_MODE,
      drawMode: state,
    });
  };

  const setTableState = (tableState: any) => {
    dispatch({
      type: SET_TABLE_STATE,
      tableState: tableState,
    });
  };

  const setBoardState = (boardState: any) => {
    dispatch({
      type: SET_BOARD_STATE,
      boardState: boardState,
    });
  };

  const setEstateMapFilterFeatureIds = (filterObject: {
    [estateId: string]: { [mapId: string]: string[] | null };
  }) => {
    dispatch({
      type: SET_ESTATE_MAP_FILTER_FEATURE_IDS,
      estateMapFilterFeatureIds: filterObject,
    });
  };

  const setCategories = (categories: null | string[]) => {
    dispatch({
      type: SET_CATEGORIES,
      categories: categories,
    });
  };

  const setMapCategoriesFilterLogicType = (type: CategoriesFilterLogicType) => {
    dispatch({
      type: SET_MAP_CATEGORIES_FILTER_LOGIC_TYPE,
      categoriesFilterLogicType: type,
    });
  };

  const setActiveExternalLayersLegend = (legends: {title: string, legends: FirestoreLayerLegend[]}[]) => {
    dispatch({
      type: SET_ACTIVE_EXTERNAL_LAYERS_LEGEND,
      activeExternalLayersLegend: legends,
    });
  };

  const setNotesLayerState = (state: boolean) => {
    dispatch({
      type: SET_NOTES_LAYER_STATE,
      notesLayerActive: state,
    });
  };

  // --- Fetch data --- //
  // Activities
  const fetchActivities = async (projectId: string) => {
    dispatch({ type: MAP_DATA_API_CALL_START });
    try {
      const path = `activities`;
      // Set up query according to user role in project
      if (!user || !user.id) return;
      // Find user role
      let userProjectRole: MapProjectRoles = "viewer";
      if (projects && projects[projectId] && projects[projectId].roles) {
        if (projects[projectId].roles![user.id]) {
          userProjectRole = projects[projectId].roles![user.id].role as unknown as MapProjectRoles;
        }
      }
      // Check for org-to-org subscription and set user role to admin if found
      if (userProjectRole !== "admin" && orgToOrgConsumerSubscriptions && orgToOrgConsumerSubscriptions.length > 0) {
        if (projects[projectId] && projects[projectId].estateId) {
          const orgToOgSub = orgToOrgConsumerSubscriptions.find(el => el.estateId === projects[projectId].estateId);
          if (orgToOgSub) {
            userProjectRole = "admin";
          }
        }
      }
      let q = query(collection(db, path), where("projectId", "==", projectId));
      if (userProjectRole !== "admin") {
        q = query(
          collection(db, path),
          where("projectId", "==", projectId),
          where("responsible.id", "==", user.id)
        );
      }
      const unsub = onSnapshot(
        q,
        (qs) => {
          qs.docChanges().forEach((change: any) => {
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_ACTIVITY_SUCCESS,
                  payload: { ...change.doc.data() },
                });
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_ACTIVITY_SUCCESS,
                  payload: { ...change.doc.data() },
                });
                break;
              case "removed":
                if (state.selectedActivityId === change.doc.data().id) setSelectedActivityId(null);
                dispatch({
                  type: REMOVED_ACTIVITY_SUCCESS,
                  payload: { ...change.doc.data() },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };
  // Map features
  const fetchFeatures = async (
    id: string,
    type: "maps" | "activities",
    authType: "normal" | "anonymous",
    categories?: string[] | null
  ) => {
    dispatch({ type: MAP_DATA_API_CALL_START });
    try {
      const path = `${type}/${id}/features`;
      // Check for authentication type
      let q = null;
      if (authType === "anonymous") {
        // Check if audience is active on the underlaying map
        const parDoc = await getDoc(doc(db, `${type}/${id}`));
        if (!parDoc.exists() || !parDoc.data().audienceActive) {
          if (type === "maps") {
            setSelectedMapId(null);
          } else if (type === "activities") {
            setSelectedProjectId(null);
          }
          setSelectedFeatureId(null);
          return;
        }
        q = query(collection(db, path), where("categories", "array-contains", "audience"));
      } else {
        if (categories && categories.length > 0) {
          q = query(collection(db, path), where("categories", "array-contains-any", categories));
        } else {
          q = collection(db, path);
        }
      }
      const unsub = onSnapshot(
        q,
        (qs) => {
          // const data = [] as any;
          qs.docChanges().forEach((change: any) => {
            // Try to convert data to geojson
            let newData = null;
            try {
              newData = fromFirestoreFeatureToGeoJson(change.doc.data());
            } catch (error) {
              console.error("Error converting feature to geojson", error);
              return;
            }
            let changeType = change.type;
            // Filter according to private category and createdByUserId, and internal and createdByOrgId
            if (newData && newData.properties) {
              const { createdByUserId, createdByOrgId, categories } = newData.properties;
              if (
                createdByUserId &&
                createdByUserId !== user?.id &&
                categories &&
                categories.includes("private")
              )
                changeType = "removed";
              if (
                createdByOrgId &&
                createdByOrgId !== user?.orgId &&
                categories &&
                categories.includes("internal")
              )
                changeType = "removed";
            }

            switch (changeType) {
              case "added":
                dispatch({
                  type: ADDED_FEATURE_SUCCESS,
                  payload: { dataConv: newData },
                });
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_FEATURE_SUCCESS,
                  payload: { dataConv: newData },
                });
                break;
              case "removed":
                dispatch({
                  type: REMOVED_FEATURE_SUCCESS,
                  payload: { dataConv: newData },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      console.error("Fetch error", error);
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };
  // Activity features
  const fetchActivityFeatures = async (activityId: string) => {
    dispatch({ type: MAP_DATA_API_CALL_START });
    try {
      const path = `activities/${activityId}/features`;
      const q = query(collection(db, path));
      const unsub = onSnapshot(
        q,
        (qs) => {
          qs.docChanges().forEach((change: any) => {
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_ACTIVITY_FEATURE_SUCCESS,
                  payload: { dataConv: fromFirestoreFeatureToGeoJson(change.doc.data()) },
                });
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_ACTIVITY_FEATURE_SUCCESS,
                  payload: { dataConv: fromFirestoreFeatureToGeoJson(change.doc.data()) },
                });
                break;
              case "removed":
                dispatch({
                  type: REMOVED_ACTIVITY_FEATURE_SUCCESS,
                  payload: { dataConv: fromFirestoreFeatureToGeoJson(change.doc.data()) },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };

  const fetchStandData = async (mapId: string) => {
    dispatch({ type: MAP_DATA_API_CALL_START });
    try {
      const path = `maps/${mapId}/stands`;
      const q = query(collection(db, path), where("status", "==", "current"));
      const unsub = onSnapshot(
        q,
        (qs) => {
          const data = [] as any;
          qs.forEach((doc: any) => {
            data.push(doc.data());
          });
          dispatch({
            type: FETCH_STAND_DATA_SUCCESS,
            mapId: mapId,
            payload: data,
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };

  const fetchThinningData = async (id: string, type: "mapId" | "activityId" | "standId") => {
    dispatch({ type: MAP_DATA_API_CALL_START });
    try {
      const path = `thinnings`;
      let q = query(collection(db, path), where("mapId", "==", id));
      if (type === "activityId") {
        q = query(collection(db, path), where("activityId", "==", id));
      } else if (type === "standId") {
        q = query(collection(db, path), where("standId", "==", id));
      }
      const unsub = onSnapshot(
        q,
        (qs) => {
          qs.docChanges().forEach((change: any) => {
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_THINNING_DATA_SUCCESS,
                  payload: { ...change.doc.data() },
                });
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_THINNING_DATA_SUCCESS,
                  payload: { ...change.doc.data() },
                });
                break;
              case "removed":
                dispatch({
                  type: REMOVED_THINNING_DATA_SUCCESS,
                  payload: { ...change.doc.data() },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };

  const fetchNotes = async (mapId: string) => {
    dispatch({ type: MAP_DATA_API_CALL_START });
    try {
      const path = `maps/${mapId}/notes`;
      const q = query(collection(db, path));
      const unsub = onSnapshot(
        q,
        (qs) => {
          qs.docChanges().forEach((change: any) => {
            switch (change.type) {
              case "added":
                dispatch({
                  type: ADDED_NOTE_SUCCESS,
                  payload: { ...change.doc.data() },
                });
                break;
              case "modified":
                dispatch({
                  type: MODIFIED_NOTE_SUCCESS,
                  payload: { ...change.doc.data() },
                });
                break;
              case "removed":
                dispatch({
                  type: REMOVED_NOTE_SUCCESS,
                  payload: { ...change.doc.data() },
                });
                break;
              default:
            }
          });
        },
        (error) => {
          console.info("ERROR TEST", error);
          console.trace();
        }
      );
      firestoreEventListeners.push(unsub);
    } catch (error) {}
  };
  // --- Activities --- //
  const saveActivity = async (props: SaveActivityProps) => {
    dispatch({ type: MAP_DATA_API_CALL_START });
    try {
      const { id, projectId, dontCheckForMapFeatures, thinningData, payload } = props;
      let docId: string;
      let docRef: DocumentReference;
      if (!projectId) throw new Error("No project id found!!!");
      if (!id) {
        // Create document path string
        docRef = doc(collection(db, "activities"));
        docId = docRef.id;
        await setDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(payload),
          projectId: projectId,
          id: docId,
          status: "current",
          creationDate: dayjs().format(),
          lastModifiedDate: dayjs().format(),
        });
        // Log event in mixpanel
        mixpanel.track(mixpanelEvents.activityCreated);
      } else {
        docRef = doc(db, `activities/${id}`);
        docId = id;
        await updateDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(payload),
          projectId: projectId,
          lastModifiedDate: dayjs().format(),
        });
      }
      // Go through mapFeatureRefs and save features under activity as origins and editable features
      const { mapFeatureRefs } = payload;
      if (!dontCheckForMapFeatures && mapFeatureRefs && mapFeatureRefs.length > 0) {
        mapFeatureRefs.forEach(async (el: string) => {
          const splitString = el.split("/");
          const mapId = splitString[0];
          const featureId = splitString[1];
          let originsFeatureMetric = null;
          // Set feature from reducer state
          let feat = state.features[mapId][featureId];
          let originsFeat = { ...feat };
          // Check if origins feature already exist under activity
          const featPath = `activities/${docId}/features/${featureId}`;
          const featDocRef = doc(db, featPath);
          const featDocSnap = await getDoc(featDocRef);
          const docExists = featDocSnap.exists();
          // Set/update origins feature
          if (originsFeat) {
            originsFeat.properties = {
              ...originsFeat.properties,
              activityId: docId,
              originFeature: true,
            };
            originsFeatureMetric = originsFeat.properties.featureMetric;
            await saveFeature(
              {
                id: featureId,
                activityId: docId,
                payload: originsFeat,
              },
              "activities"
            );
          }
          // If feature does not exist under activity also add editable feature
          if (!docExists) {
            let editableFeat = { ...feat };
            // If feature is a line or polygon set style
            let newStyleObject = { ...editableFeat.properties.styleObject };
            if (
              ["LineString", "MultiLineString", "Polygon", "MultiPolygon"].includes(
                feat.geometry.type
              )
            ) {
              newStyleObject = {
                fillColor: "Cyan",
                fillOpacity: 0,
                lineColor: "Cyan",
                lineStyle: "Solid",
                lineWidth: 3,
                polygonStyle: "Filled",
              };
            }
            if (editableFeat) {
              editableFeat.properties = {
                ...editableFeat.properties,
                id: null,
                activityId: docId,
                originsFeatureId: featureId,
                originsFeatureMetric: originsFeatureMetric,
                originFeature: false,
                mainSpecies: "",
                mainSpeciesYear: "",
                section: [],
                coverage: null,
                areaBearing: false,
                title: payload.name ? payload.name : "",
                styleObject: newStyleObject,
              };
              await saveFeature(
                {
                  activityId: docId,
                  payload: editableFeat,
                },
                "activities"
              );
            }
          }
        });
      }
      // Save possible thinning data
      if (thinningData && thinningData.length > 0) {
        thinningData.forEach(async (thinning) => {
          const { mapId, standId, thinningId, featureId, disabled, thinningPercentage } = thinning;
          if (thinningPercentage > 0 || !thinningId.startsWith("new-")) {
            await saveThinning({
              id: thinningId,
              mapId,
              featureId,
              activityId: docId,
              standId,
              usedInProjection: disabled !== undefined ? disabled : false,
              percentageThinning: thinningPercentage,
              executionDate: payload.expireDate ? payload.expireDate : "",
              executed: payload.activityStatus === "done" ? true : false,
              createdByUserId: user?.id,
            });
          }
        });
      }
      // Set selected activity id if different from last saved activity
      if (state.selectedActivityId !== docId) setSelectedActivityId(docId);
      dispatch({ type: SAVE_ACTIVITY_SUCCESS });
    } catch (error) {
      console.error(error);
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };

  const updateActivityStatus = async (id: string, status: DEFAULT_ACTIVITY_STATUS_TYPE) => {
    try {
      const path = `activities/${id}`;
      const docRef = doc(db, path);
      await updateDoc(docRef, {
        activityStatus: status,
        lastModifiedDate: dayjs().format(),
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };

  const deleteActivity = async (id: string) => {
    try {
      if (state.selectedActivityId === id) setSelectedActivityId(null);
      const path = `activities/${id}`;
      const docRef = doc(db, path);
      await deleteDoc(docRef);
      // Log event in mixpanel
      mixpanel.track(mixpanelEvents.activityDeleted);
    } catch (error) {
      console.error(error);
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };

  // --- Features --- //
  // Save feature
  const saveFeature = async (props: SaveFeatureProps, type: "maps" | "activities") => {
    // const PURGE = true;
    dispatch({ type: MAP_DATA_API_CALL_START });
    // Convert data
    let properties: any = {};
    if (props.payload) {
      const data = fromGeoJsonFeatureToFirestore(props.payload);
      const { creationDate, lastModifiedDate, id, ...remainderProps } = data.firestoreFeature;
      properties = remainderProps;
    }
    // Check for parent id
    let parId = props.mapId;
    if (type === "activities") parId = props.activityId;
    if (!parId) throw new Error("No parent id found!!!");
    try {
      let id = props.id;
      if (!id) {
        // Create document path string
        const path = `${type}/${parId}/features`;
        const docRef = doc(collection(db, path));
        id = docRef.id;
        await setDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(properties),
          creationDate: dayjs().format(),
          lastModifiedDate: dayjs().format(),
          mapId: props.mapId ? parId : null,
          activityId: props.activityId ? parId : null,
          createdByUserId: user?.id,
          createdByOrgId: user?.orgId,
          id: id,
        });
      } else {
        const path = `${type}/${parId}/features/${props.id}`;
        const docRef = doc(db, path);
        // Needed because when creating an activity we copy the original feature to the activity features
        // and then the feauture already has an id
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          await setDoc(docRef, {
            ...dataObjectWithoutUndefinedFields(properties),
            id: id,
            lastModifiedDate: dayjs().format(),
            createdByUserId: properties.createdByUserId ? properties.createdByUserId : user?.id,
            createdByOrgId: properties.createdByOrgId ? properties.createdByOrgId : user?.orgId,
          });
        } else {
          await updateDoc(docRef, {
            ...dataObjectWithoutUndefinedFields(properties),
            lastModifiedDate: dayjs().format(),
            createdByUserId: properties.createdByUserId ? properties.createdByUserId : user?.id,
            createdByOrgId: properties.createdByOrgId ? properties.createdByOrgId : user?.orgId,
          });
        }
      }
      dispatch({
        type: SAVE_FEATURE_SUCCESS,
        lastSavedFeature: { id: id as string, geometry: props.payload.geometry },
      });
    } catch (error) {
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };

  // Delete feature
  const deleteFeature = async (props: DeleteFeatureProps, type: "maps" | "activities") => {
    dispatch({ type: MAP_DATA_API_CALL_START });
    try {
      if (state.selectedFeatureId === props.id) setSelectedFeatureId(null);
      let parId = props.mapId;
      if (type === "activities") parId = props.activityId;
      if (!parId) throw new Error("No parent id found!!!");
      const path = `${type}/${parId}/features/${props.id}`;
      await deleteDoc(doc(db, path));
      // If type is activities also delete origins feature
      if (props.originsFeatureId && type === "activities") {
        const orgPath = `${type}/${parId}/features/${props.originsFeatureId}`;
        await deleteDoc(doc(db, orgPath));
      }
      dispatch({ type: DELETE_FEATURE_SUCCESS });
    } catch (error) {
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };

  // --- Stand data --- //
  const saveStandData = async (props: SaveStandDataProps) => {
    dispatch({ type: MAP_DATA_API_CALL_START });
    try {
      // Fetch feature information
      const featPath = `maps/${props.mapId}/features/${props.featureId}`;
      const featDocRef = doc(db, featPath);
      const featDocSnap = await getDoc(featDocRef);
      // Check that feature doc exist on db
      if (!featDocSnap.exists())
        throw new Error("Not possible to save stand data on non-existing feature!!!");
      let featData = { ...featDocSnap.data() };
      let newStandIds = featData.standIds ? featData.standIds : [];
      // run through each stand data
      props.payload.forEach(async (el, idx) => {
        // Check for new stand data
        if (el.id.startsWith("new-")) {
          const path = `maps/${props.mapId}/stands`;
          const docRef = doc(collection(db, path));
          const standId = docRef.id;
          // Insert id in feature standIds array
          if (idx === 0) {
            newStandIds.unshift(standId);
          } else {
            newStandIds.push(standId);
          }
          await setDoc(docRef, {
            ...dataObjectWithoutUndefinedFields(el),
            creationDate: dayjs().format(),
            lastModifiedDate: dayjs().format(),
            mapId: props.mapId,
            featureId: props.featureId,
            id: standId,
            status: "current",
          });
        } else {
          // EXISTING DOC. TODO : create revision
          // Update existing stand data
          const path = `maps/${props.mapId}/stands/${el.id}`;
          const docRef = doc(db, path);
          // Check if stand has been deleted
          if (el.status === "deleted") {
            // Remove stand id from feature standIds array
            const standIdx = newStandIds.indexOf(el.id);
            if (standIdx !== -1) newStandIds.splice(standIdx, 1);
            // Delete stand data from firestore
            await deleteDoc(docRef);
            return;
          } else {
            // Check if stand id is in feature
            const standIdx = newStandIds.indexOf(el.id);
            if (standIdx === -1) {
              if (idx === 0) newStandIds = [el.id, ...newStandIds];
            } else {
              if (idx === 0 && standIdx !== 0) {
                newStandIds = [el.id, ...newStandIds.splice(standIdx, 1)];
              }
            }
          }
          updateDoc(docRef, {
            ...dataObjectWithoutUndefinedFields(el),
            lastModifiedDate: dayjs().format(),
          });
        }
      });
      // Update feature standIds array in db
      updateDoc(featDocRef, {
        mainSpecies: props.payload[0].species,
        mainSpeciesYear: props.payload[0].year ? props.payload[0].year : "",
        standIds: newStandIds,
        lastModifiedDate: dayjs().format(),
      });

      dispatch({ type: SAVE_STAND_DATA_SUCCESS });
    } catch (error) {
      console.error(error);
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };

  // Thinning
  const saveThinning = async (props: SaveThinningProps) => {
    dispatch({ type: MAP_DATA_API_CALL_START });
    try {
      // Check for new thinning
      if (props.id.startsWith("new-") && props.percentageThinning !== 0) {
        const docRef = doc(collection(db, "thinnings"));
        const id = docRef.id;
        await setDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(props),
          creationDate: dayjs().format(),
          lastModifiedDate: dayjs().format(),
          id: id,
        });
      } else {
        if (props.percentageThinning === 0) {
          await deleteDoc(doc(db, `thinnings/${props.id}`));
        } else {
          await updateDoc(doc(db, `thinnings/${props.id}`), {
            ...dataObjectWithoutUndefinedFields(props),
            lastModifiedDate: dayjs().format(),
          });
        }
      }
      dispatch({ type: SAVE_THINNING_DATA_SUCCESS });
    } catch (error) {
      console.error(error);
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };

  // Notes
  const saveNote = async (props: SaveNoteProps) => {
    dispatch({ type: MAP_DATA_API_CALL_START });
    try {
      if (props.id.startsWith("new-")) {
        const path = `maps/${props.mapId}/notes`;
        const docRef = doc(collection(db, path));
        await setDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(props),
          creationDate: dayjs().format(),
          lastModifiedDate: dayjs().format(),
          createdById: user?.id,
          id: docRef.id,
        });
        // Log event in mixpanel
        mixpanel.track(mixpanelEvents.noteCreated);
      } else {
        const path = `maps/${props.mapId}/notes/${props.id}`;
        const docRef = doc(db, path);
        await updateDoc(docRef, {
          ...dataObjectWithoutUndefinedFields(props),
          lastModifiedDate: dayjs().format(),
        });
      }
      dispatch({ type: SAVE_STAND_DATA_SUCCESS });
    } catch (error) {
      console.error(error);
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };

  const deleteNote = async ({ mapId, id }: DeleteNoteProps) => {
    dispatch({ type: MAP_DATA_API_CALL_START });
    try {
      const path = `maps/${mapId}/notes/${id}`;
      await deleteDoc(doc(db, path));
      // Log event in mixpanel
      mixpanel.track(mixpanelEvents.noteDeleted);
      dispatch({ type: DELETE_FEATURE_SUCCESS });
    } catch (error) {
      dispatch({ type: MAP_DATA_API_CALL_FAIL, error: error });
    }
  };

  return (
    <MapDataContext.Provider
      value={{
        ...state,
        setSelectedFeatureId,
        setSelectedFeature,
        setSelectedActivityId,
        setRemoveFeatureId,
        setAddStandData,
        setUseProgramDefinedSetting,
        setAreaBearingSetting,
        setFeatureEditMode,
        setDrawMode,
        setTableState,
        setBoardState,
        setEstateMapFilterFeatureIds,
        setCategories,
        setMapCategoriesFilterLogicType,
        setActiveExternalLayersLegend,
        setNotesLayerState,
        fetchActivities,
        fetchActivityFeatures,
        fetchFeatures,
        fetchStandData,
        saveActivity,
        updateActivityStatus,
        deleteActivity,
        saveFeature,
        deleteFeature,
        saveStandData,
        saveThinning,
        saveNote,
        deleteNote,
      }}
    >
      {children}
    </MapDataContext.Provider>
  );
}

export default MapDataContext;
